import React from "react"
import Layout from "../components/layout.js"
import Seo from "../components/seo.js"
import { graphql } from "gatsby"
import ImageCarousel from "../components/imageCarousel.js";
import JSONData from "../data/stained-glass.json"
import StainedGlassList from "../components/stainedGlassList"

const StainedGlass = ({ data }) => {

    const images = data.allStainedGlassJson.edges.map(({ node }) => node.image.stainedGlassImage);

    return (
        <Layout>
            <Seo title="Stained Glass" />
            <h2>Stained Glass</h2>
            <ImageCarousel images={images} />
            <StainedGlassList stainedGlass={JSONData} />
        </Layout>

    )
}

export const query = graphql`
  query {
    allStainedGlassJson {
        edges {
          node {
            id
            name
            image {
              stainedGlassImage: childImageSharp {
                gatsbyImageData(height:500, placeholder: BLURRED, formats: [WEBP, AUTO]),
              }
            }
          }
        }
      }
  }
`

export default StainedGlass
import React from "react"
import StainedGlass from "./stainedGlass"

const StainedGlassList = ({stainedGlass, title}) => {

   let index=0;
    return (
        <div>
            <p></p>
        {/* <h3>{title}</h3> */}
        <ul>
            {stainedGlass.sort((a, b) => a.year - b.year).map((stainedGlassData) => {
                return <StainedGlass stainedGlass={stainedGlassData} key={index++} />
            })}
        </ul>
        </div>
    )
}
export default StainedGlassList